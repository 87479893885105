import consumer from "./consumer"


App.createBoardViewChannel = function createBoardViewChannel( board, main_board = true )
{
	var $uuid = $('head').data('uuid');
 return consumer.subscriptions.create({channel: "BoardViewChannel", id: board, main_board: main_board}, {
  connected() {
    // Called when the subscription is ready for use on the server
  },

  disconnected() {
    // Called when the subscription has been terminated by the server
	//channel_disconnect();
  },

  received(data) {
    if( data["uuid"] == $uuid ) 
    {
	   // console.log("ignore own message");
      return;
    }

	  if( data["action"] == "user_joined" )
	  {
		  channel_user_joined( data["user"], data["display_name"],data["id"], data["color"] );
		  
	  }
	  if( data["action"] == "user_left" )
	  {
		  channel_user_left( data["user"], data["display_name"], data["id"] );
		  
	  }
	  if( data["action"] == "move_user" )
	  {
		  channel_move_user( data["list"]  );
		  
	  }
	  if( data["action"] == "move_stack" )
	  {
		  channel_move_stack( data["stack_id"], data["left"], data["top"]);
		  
	  }
	  if( data["action"] == "locate_group_in_stack" )
	  {
		  channel_locate_group_in_stack( data["group_id"], data["stack_id"], data["order"]);
		  
	  }
	  if( data["action"] == "update_user_pointer" )
	  {
		  channel_update_user_pointer( data["id"], data["left"], data["top"], data["color"] );
		  
	  }
	  if( data["action"] == "setup_new_stack" )
	  {
		  channel_setup_new_stack( data["stack_id"], data["left"], data["top"], data["groups"], data["board"]);
		  
	  }
	  if( data["action"] == "setup_new_card" )
	  {
		  channel_setup_new_card( data["content"], data["column"], data["id"], data["color"], data["user_id"], data["user_short"], data["user_name"], data["tag_ids"]);
		  
	  }
	  if( data["action"] == "locate_card_in_column" )
	  {
		  channel_locate_card_in_column( data["card_id"], data["column_id"], data["order"]);
		  
	  }
	  if( data["action"] == "set_group_card_size" )
	  {
		  channel_set_group_card_size( data["group_id"], data["size"] );
		  
	  }
	  if( data["action"] == "remove_group_column" )
	  {
		  channel_remove_group_column( data["group_id"] );
		  
	  }
	  if( data["action"] == "add_column_to_group" )
	  {
		  channel_add_column_to_group( data["group_id"], data["column_id"] );
		  
	  }
	  if( data["action"] == "transform_col_to_groups" )
	  {
		  channel_transform_col_to_groups( data["col_id"], data["group_data"]);
	  }
	  if( data["action"] == "set_cards_color" )
	  {
		  channel_set_cards_color( data["card_ids"], data["color"] );
		  
	  }
	  if( data["action"] == "set_defaults_color" )
	  {
		  channel_set_defaults_color( data["group_ids"], data["color"] );
		  
	  }
	  if( data["action"] == "update_edit_field" )
	  {
		  channel_update_edit_field( data["mode"], data["id"], data["text"], data["status"] );
		  
	  }
	  if( data["action"] == "remove_card" )
	  {
		  channel_remove_card( data["id"] );
		  
	  }
	  if( data["action"] == "update_shape_text" )
	  {
		  channel_update_shape_text( data["id"], data["text"]);
		  
	  }
	  if( data["action"] == "move_shape" )
	  {
		  channel_move_shape( data["id"], data["left"], data["top"] );
		  
	  }
	  if( data["action"] == "shape_attr" )
	  {
		  channel_shape_attr( data["id"], data["attr"], data["value"] );
		  
	  }
	  if( data["action"] == "remove_group" )
	  {
		  channel_remove_group( data["group_id"] );
		  
	  }
	  if( data["action"] == "remove_shape" )
	  {
		  channel_remove_shape( data["id"]  );
		  
	  }
	  if( data["action"] == "remove_portal" )
	  {
		  channel_remove_portal( data["id"]  );
		  
	  }
	  if( data["action"] == "order_shape" )
	  {
		  channel_order_shape( data["id"], data["order"]  );
		  
	  }
	  if( data["action"] == "size_shape" )
	  {
		  channel_size_shape( data["id"], data["left"], data["top"], data["width"], data["height"] );
		  
	  }
	  if( data["action"] == "card_vote" )
	  {
		  channel_card_vote( data["id"], data["mode"] );
		  
	  }
	  if( data["action"] == "vote_control" )
	  {
		  channel_vote_control( data["mode"], data["votes"] );
		  
	  }
	  if( data["action"] == "move_portal")
	  {
		  channel_move_portal( data["id"], data["left"], data["top"], data["width"], data["height"], data["scroll_left"], data["scroll_top"] );
		  
	  }
	  if( data["action"] == "add_shape" )
	  {
		  channel_add_shape( data["id"], data["shape"], data["left"], data["top"], data["width"], data["height"], data["fill"], data["stroke"], data["text"] );
		  
	  }
	  if( data["action"] == "add_portal" )
	  {
		  channel_add_portal( data["id"], data["left"], data["top"], data["width"], data["height"] );
		  
	  }
	  if( data["action"] == "clone_stack" )
	  {
		  channel_clone_stack( data["data"], data["board_id"]);
		  
	  }
	  if( data["action"] == "change_portal_view" )
	  {
		  channel_change_portal_view( data["p_id"], data["board_id"]);
		  
	  }
	  if( data["action"] == "setup_empty_stack" )
	  {
          	channel_setup_empty_stack( data["board_id"], data["stack_id"], data["group_id"], data["col_id"], data["title"], data["left"], data["top"] );
		  
	  }
	  if( data["action"] == "remove_tag" )
	  {
          	channel_remove_tag( data["id"] );
	  }
	  if( data["action"] == "update_group_default_tags" )
	  {
			channel_update_group_default_tags( data["group_id"], data["tag_ids"]);
	  }
	  if( data["action"] == "tag_multi_cards" )
	  {
          	tag_multi_cards( data["id"], data["card_ids"], data["mode"]);
	  }
	  if( data["action"] == "update_tag" )
	  {
          	channel_update_tag( data["id"], data["name"], data["bg_color"], data["color"]);
	  }
	  if( data["action"] == "update_card_tags" )
	  {
          	channel_update_card_tags( data["card_id"], data["tags"]);
	  }
	  if( data["action"] == "action_tags" )
	  {
          	channel_action_tags( data["ids"], data["setting"], data["mode"]);
	  }
    // Called when there's incoming data on the websocket for this channel
  },

  action_tags: function( ids, setting, mode ) {
    return this.perform('action_tags', {ids: ids, setting: setting, mode: mode, uuid: $uuid});
  },
  tag_multi_cards: function( id, card_ids, mode ) {
    return this.perform('tag_multi_cards', {id: id, card_ids: card_ids, mode: mode, uuid: $uuid});
  },
  remove_tag: function( id ) {
    return this.perform('remove_tag', {id: id, uuid: $uuid});
  },
  update_tag: function( id, name, bg_color, color ) {
    return this.perform('update_tag', {id: id, name: name,  bg_color: bg_color, color: color,uuid: $uuid});
  },
  update_card_tags: function( id, tags ) {
    return this.perform('update_card_tags', {card_id: id, tags: tags, uuid: $uuid});
  },
  move_stack: function( id, left, top ) {
    return this.perform('move_stack', {stack_id: id, left: left, top: top, uuid: $uuid});
  },
  transform_col_to_groups: function( col_id, group_data ) {
    return this.perform('transform_col_to_groups', {col_id: col_id, group_data: group_data, uuid: $uuid});
  },
  add_column_to_group: function( group_id, column_id ) {
    return this.perform('add_column_to_group', {group_id: group_id, column_id: column_id, uuid: $uuid});
  },
  locate_group_in_stack: function( g_id, s_id, order ) {
    return this.perform('locate_group_in_stack', {group_id: g_id, stack_id: s_id, order: order, uuid: $uuid});
  },
  setup_new_stack: function( id, left, top, group_ids ) {
    return this.perform('setup_new_stack', {stack_id: id, left: left, top: top, groups: group_ids, board: board, uuid: $uuid});
  },
  locate_card_in_column: function( card_id, column_id, order ) {
    return this.perform('locate_card_in_column', {card_id: card_id, column_id: column_id, order: order, uuid: $uuid});
  },
  add_shape: function(id, shape, left, top, width, height, fill, stroke, text){ 
    return this.perform('add_shape', {id: id, shape: shape, left: left, top: top, width: width, height: height, fill: fill, stroke: stroke, text: text, uuid: $uuid});
  },
  add_portal: function(id, left, top, width, height){ 
    return this.perform('add_portal', {id: id, left: left, top: top, width: width, height: height, uuid: $uuid});
  },
  update_user_pointer: function( id, left, top ) {
    return this.perform('update_user_pointer', {id: id, left: left, top: top, uuid: $uuid});
  },
  move_shape: function( id, left, top ) {
    return this.perform('move_shape', {id: id, left: left, top: top, uuid: $uuid});
  },
  move_portal: function( id, left, top, width, height, scroll_left, scroll_top ) {
    return this.perform('move_portal', {id: id, left: left, top: top, width: width, height: height, scroll_left: scroll_left, scroll_top: scroll_top, uuid: $uuid});
  },
  card_vote: function( id, mode ) {
    return this.perform('card_vote', {id: id, mode: mode, uuid: $uuid});
  },
  change_portal_view: function( p_id, board_id ) {
    return this.perform('change_portal_view', {p_id: p_id, board_id: board_id, uuid: $uuid});
  },
  clone_stack: function( data, board_id ) {
    return this.perform('clone_stack', {data: data, board_id: board_id, uuid: $uuid});
  },
  vote_control: function( mode, votes ) {
    return this.perform('vote_control', {mode: mode, votes: votes, uuid: $uuid});
  },
  order_shape: function( id, order ) {
    return this.perform('order_shape', {id: id, order: order, uuid: $uuid});
  },
  update_group_default_tags: function( group_id, tag_ids ) {
    return this.perform('update_group_default_tags', {group_id: group_id, tag_ids: tag_ids, uuid: $uuid});
  },
  shape_attr: function( id, attr, value ) {
    return this.perform('shape_attr', {id: id, attr: attr, value: value, uuid: $uuid});
  },
  update_shape_text: function( id, text ) {
    return this.perform('update_shape_text', {id: id, text: text, uuid: $uuid});
  },
  size_shape: function( id, left, top, width, height ) {
    return this.perform('size_shape', {id: id, left: left, top: top, width: width, height: height, uuid: $uuid});
  },
  setup_new_card: function( content, column, id, color, user_id, user_short, user_name, tag_ids) {
    return this.perform('setup_new_card', {content: content, column: column, id: id, color: color, user_id: user_id, user_short: user_short, user_name: user_name, tag_ids: tag_ids, uuid: $uuid});
  },
  remove_group_column: function( group_id ) {
    return this.perform('remove_group_column', {group_id: group_id, uuid: $uuid});
  },
  remove_group: function( group_id ) {
    return this.perform('remove_group', {group_id: group_id, uuid: $uuid});
  },
  remove_card: function( id ) {
    return this.perform('remove_card', {id: id, uuid: $uuid});
  },
  remove_portal: function( id ) {
    return this.perform('remove_portal', {id: id, uuid: $uuid});
  },
  remove_shape: function( id ) {
    return this.perform('remove_shape', {id: id, uuid: $uuid});
  },
  set_group_card_size: function( group_id, size ) {
    return this.perform('set_group_card_size', {group_id: group_id, size: size, uuid: $uuid});
  },
  set_cards_color: function( card_ids, color ) {
    return this.perform('set_cards_color', {card_ids: card_ids, color: color, uuid: $uuid});
  },
  set_defaults_color: function( group_ids, color ) {
    return this.perform('set_defaults_color', {group_ids: group_ids, color: color, uuid: $uuid});
  },
  update_edit_field: function( mode, id, text, status ) {
    return this.perform('update_edit_field', {mode:mode, id: id, text: text, status: status, uuid: $uuid});
  },
  setup_empty_stack: function( board_id, stack_id, group_id, col_id, title, left, top ) {
    return this.perform('setup_empty_stack', {board_id: board_id, stack_id: stack_id, group_id: group_id, col_id: col_id, title: title, left: left, top: top, uuid: $uuid});
  }
});
}
