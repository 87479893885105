// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

window.App = {}

require("@rails/ujs").start()
require("turbolinks").start()
//require("@rails/activestorage").start()
require("channels")
require( "bootstrap")




require("jquery-ui/ui/widgets/autocomplete");
require("jquery-ui/ui/widgets/droppable");
require("jquery-ui/ui/widgets/draggable");
require("jquery-ui/ui/widgets/sortable");
require("jquery-ui/ui/widgets/slider");
require("jquery-ui/ui/widgets/button");
require("jquery-ui/ui/widgets/resizable");
require("jquery-ui/ui/widgets/spinner");
require("jquery-ui/ui/widgets/dialog");
require("jquery-ui/ui/widgets/tabs");
require("jquery-ui/ui/effect");
require("jquery-ui/ui/effects/effect-slide");
require("jquery-ui/ui/effects/effect-pulsate");
require("jquery-ui/ui/effects/effect-highlight");
require('jquery-ui/themes/base/all');
//require( '@fortawesome/fontawesome-free/js/all');
//require('@svgdotjs/svg.draggable.js/dist/svg.draggable.js');
require('./svg.js');
require('./svg.draggable.js');
require('./svg.select.js');
require('./svg.resize.js');
require('./spectrum.js');
require('./jquery.fontselect.min.js');
require('./jquery.ui.touch-punch.min.js');
require('./jquery.doubletap.js');
require('./jquery.vnm.confettiButton.min.js');
require("./bootstrap-tokenfield.js");
//require('./jquery.dataTables.min.js');


global.Rails = Rails;
global.SVG = SVG;


window.$ = window.jQuery = $ // make $ available in console for debugging

import {scaleLinear} from "d3-scale";

global.scaleLinear =  scaleLinear;

//FontAwesomeConfig.autoReplaceSvg = false;

//require('@svgdotjs/svg.draggable.js/dist/svg.draggable.js');
require('pivot.js');
//require('plotly.js');
//require('plotly_renderers.min.js');


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)
